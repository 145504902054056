function Recaptcha_form_ajax_submit(form, submit_btn) {
  const recaptcha = form.find('.recaptcha')
  if (recaptcha.length) {
    grecaptcha.ready(function() {
      grecaptcha.render(recaptcha.get(0), {
        'sitekey': recaptcha.data('key'),
        'size': 'invisible',
        'callback': function (response) {
          recaptcha.find('[name=g-recaptcha-response]').val(response)
          form.find('.js-ajax-submit').trigger('click')
        }
      })
    })
  }

  submit_btn.on('click', function (e) {
    e.preventDefault()
    if (recaptcha.length) {
      grecaptcha.execute()
    } else {
      form.find('.js-ajax-submit').trigger('click')
    }
  })
  $(document).foundation();
}
